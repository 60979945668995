import React, { useEffect } from "react";
import CeloVsWhatsAppSPSE from "../components/pages-components/celo-vs-whatsapp/spse";
import Hero from "@components/shared/hero";
import { Container, MenuButtonGatsbyLink } from "@util/standard";
import CardContentBlock from "@components/shared/sub/cardContentBlock";
import BlocksContent from "@components/shared/sub/blockContent";
import styled from "styled-components";
import { graphql, PageProps } from "gatsby";
import FeatureColumn from "@components/pages-components/features/featureColumn";
import Cta from "@components/shared/cta";
import SEO from "@components/shared/seo";
import LayOut from "@components/layout";
import { Query, SanityCeloVsWhatsAppPageConnection } from "@graphql-types";
import { useRegionChanged } from "@util/hooks";

const StyledBlocksContent = styled(Container)`
  width: 90%;
  margin: auto;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 40px;
`;

interface Data extends Query {
  altRegions: SanityCeloVsWhatsAppPageConnection;
  pageContext: any;
}

interface Props extends PageProps {
  data: Data;
}

const CeloVsWhatsApp = (props: Props) => {
  const sanityCeloVsWhatsAppPage =
    props.data.allSanityCeloVsWhatsAppPage.nodes[0];

  if (!sanityCeloVsWhatsAppPage) {
    return null;
  }

  const alternateRegions = props.data.altRegions.nodes.map(alt => alt._rawRegion);
  useRegionChanged("celo-vs-whatsapp");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO
        seoData={sanityCeloVsWhatsAppPage.seo}
        alternateRegions={alternateRegions}
        slug={
          //@ts-ignore
          props.pageContext.slug
        }
      />

      <LayOut>
        {sanityCeloVsWhatsAppPage.hero && (
          <Hero heroContent={sanityCeloVsWhatsAppPage.hero} />
        )}
        {sanityCeloVsWhatsAppPage?.vsSPSE && (
          <CeloVsWhatsAppSPSE data={sanityCeloVsWhatsAppPage?.vsSPSE} />
        )}

        {sanityCeloVsWhatsAppPage?.topFeatureBlock && (
          <FeatureColumn
            noMinHeight
            noBlueBackground
            column={sanityCeloVsWhatsAppPage?.topFeatureBlock}
            mobileGridGap="50px"
          />
        )}

        {sanityCeloVsWhatsAppPage.complianceBadges && (
          <Container
            backgroundColor="babyBlue"
            width="100%"
            textAlign="center"
            display="flex"
            flexDirection="column"
          >
            <StyledBlocksContent>
              <BlocksContent
                blocks={sanityCeloVsWhatsAppPage?.complianceHeader?._rawContent}
              />
            </StyledBlocksContent>
            {sanityCeloVsWhatsAppPage.complianceBadges && (
              <Container
                display="flex"
                flexWrap="wrap"
                flexDirection="row"
                gridGap="30px"
                justifyContent="center"
                maxWidth="1440px"
                margin="auto"
              >
                {sanityCeloVsWhatsAppPage.complianceBadges.map(badge => {
                  return (
                    <CardContentBlock
                      cardImage={badge?.cardImage}
                      cardTitle={badge?.cardTitle}
                      cardText={badge?.cardText}
                      cardLink={badge?.cardLink}
                      key={badge?._key}
                    />
                  );
                })}
              </Container>
            )}
            {sanityCeloVsWhatsAppPage.securityBriefLink && (
              <Container margin="40px auto" display="inline-block">
                <MenuButtonGatsbyLink
                  to={sanityCeloVsWhatsAppPage.securityBriefLink.url ?? ""}
                  width="fit-content"
                  padding="10px 25px 10px 25px"
                  isPink
                >
                  {sanityCeloVsWhatsAppPage.securityBriefLink.linktext}
                </MenuButtonGatsbyLink>
              </Container>
            )}
          </Container>
        )}

        {sanityCeloVsWhatsAppPage.bottomFeatureBlock && (
          <FeatureColumn
            noMinHeight
            noBlueBackground
            column={sanityCeloVsWhatsAppPage?.bottomFeatureBlock}
          />
        )}
        {sanityCeloVsWhatsAppPage.pageBottomBlock && (
          <Cta
            content={sanityCeloVsWhatsAppPage.pageBottomBlock}
            noDownloadButtons
            showButtonInMobile
          />
        )}
      </LayOut>
    </>
  );
};

export default CeloVsWhatsApp;

export const query = graphql`
  query whatsappQuery($iban: String) {
    allSanityCeloVsWhatsAppPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
        topFeatureBlock {
          _key
          ...sanityImageTextColumn
        }
        bottomFeatureBlock {
          ...sanityImageTextColumn
        }
        complianceBadges {
          ...sanityCardContentBlock
        }
        complianceHeader {
          _rawContent
        }
        securityBriefLink {
          ...sanityLink
        }
        pageBottomBlock {
          ctaText {
            _rawContent
          }
          ctaImage {
            ...sanityImageWithMeta
          }
          ctaButton {
            linktext
            url
          }
        }

        vsSPSE {
          _key
          title
          image {
            altText

            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 250)
            }
          }
          cta {
            firstColumn {
              _rawColumnContent
            }
            secondColumn {
              _rawColumnContent
            }
            reverse
          }
        }
      }
    }
    altRegions: allSanityCeloVsWhatsAppPage {
      nodes {
        _rawRegion(resolveReferences:{maxDepth:10})
      }
    }
  }
`;
